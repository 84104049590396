<template>
  <div>
    <img :src="cardInfo.cover" style="width:100%,height:auto">
    <!-- 申请信息 -->
    <van-form @submit="onSubmit" class="content">
      <img src="@/assets/image/activity/tips.png" alt="">
      <van-cell-group inset class="group">
        <van-field
          v-model.trim="form.consigneeName"
          name="validator"
          label="姓名:"
          label-align="right"
          label-width="60px"
          maxlength="10"
          placeholder="请输入姓名(已加密)"
          :rules="[{ validator, message: '只能输入中文' }]"
        />
        <van-field
          v-model="form.walletNumber"
          label="身份证号:"
          label-align="right"
          label-width="60px"
          placeholder="请输入身份账号(已加密)"
          :rules="[{ validator:patternCard }]"
        />
        <van-field
          v-model="form.consigneePhone"
          label="电话:"
          label-align="right"
          label-width="60px"
          type="number"
          placeholder="请输入电话"
          :rules="[{ validator:patternMobile, message: '请输入正确的电话' }]"
        />
        <van-field
          v-model="form.consigneeAddressName"
          label="所在城市:"
          label-align="right"
          label-width="60px"
          maxlength="20"
          placeholder="请选择地区"
          :rules="[{ required: true, message: '地区不能为空' }]"
          @click="openOrCloseArea"
          readonly
        />
        <van-field
          v-model="form.consigneeAddressDetails"
          rows="2"
          autosize
          label="详细地址:"
          label-align="right"
          label-width="60px"
          class="form-address"
          type="textarea"
          placeholder="请填写街道-小区楼栋/乡村名称"
          :rules="[{ required: true, message: '详细地址不能为空' }]"
        />
      </van-cell-group>
      <div class="agreement">
        <van-checkbox v-model="checked" icon-size="16px" checked-color="#2359de"></van-checkbox>
        <div class="text">
          已阅读并同意
          <span class="blue" @click="agreementShow=true">《客户入网服务协议及业务协议》</span>
          <span class="blue" @click="agreementShow1=true">《关于客户个人信息收集、使用规则公告》</span>
        </div>
      </div>
      <!-- 按钮提交 -->
      <van-button block round native-type="submit" class="submit">提交</van-button>
    </van-form>
    <!-- 地址选择 -->
    <van-popup v-model:show="popupShow" position="bottom" :style="{ height: '40%' }">
      <van-area title="请选择地址" :area-list="areaList" @confirm="confirmArea" @cancel="openOrCloseArea" />
    </van-popup>
    <!-- 协议 -->
    <van-action-sheet v-model:show="agreementShow" title="中国电信用户入网协议">
      <div class="mid_top">
        <p class="p2">
          客户告知书
          <br />本人名下办理的号卡务必本人使用，禁止转卖或转赠他人。若转卖或转赠的号卡被不法分子利用从事诈骗等违法行为，根据《中华人民共和国刑法》第二百八十七条之二，号卡所有人将构成“帮助信息网络犯罪活动罪”，承担刑事责任，特此提醒。
          <br />甲方：
          <br />乙方：中国电信集团有限公司河南分公司/中国电信股份有限公司河南分公司
          <br />重要提示：
          <br />
        </p>
        <p class="p1"></p>1.甲方应当为具有完全民事行为能力的自然人、个体工商户、农村承包经营户、法人、非法人组织、获得法人或非法人组织授权的依法登记的经营单位。如甲方为无民事行为能力人或限制民事行为能力人，甲方签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。
        <br />2.在签署本协议前，请甲方或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。
        <br />3.甲方或其代理人签署本协议即视为完全理解并同意接受本协议的全部条款。
        <br />4.甲方所需的业务内容、办理方式以及资费方案请详见乙方的相关业务说明。
        <br />根据《中华人民共和国合同法》《中华人民共和国网络安全法》《中华人民共和国电信条例》及其他有关法律、法规的规定，甲乙双方在自愿、平等、公平、诚实信用的基础上，就电信服务的相关事宜，达成如下协议：
        <br />
        <p class="p1">一、用户真实身份信息登记要求</p>1.根据《电话用户真实身份信息登记规定》（工业和信息化部令第25号），甲方办理入网手续时须向乙方出示有效证件、提供真实身份信息；甲方委托他人办理入网手续的，代理人须出示甲方和代理人的有效证件，提供甲方和代理人的真实身份信息；并且配合乙方进行身份信息查验、登记，留存办理手续的记录。
        <br />2.甲方为个人用户的，办理入网时使用二代居民身份证。无法提供二代居民身份证的，可以使用的有效证件包括现役军人居民身份证、人民武装警察居民身份证、临时身份证、户口簿、港澳居民居住证或港澳居民来往内地通行证(仅限港澳居民使用)、台湾居民居住证或台湾居民来往大陆通行证(仅限台湾居民使用)、外国人永久居留身份证(仅限外国公民使用)、护照(仅限外国公民使用)。
        <br />3.甲方为单位用户的，办理入网时应当提供营业执照、或事业单位法人证书、或社会团体法人登记证书、或统一社会信用代码证书。单位办理登记的，除出示以上相应证件外，还应当出示经办人的有效证件和单位的授权书。
        <br />4.单位用户办理移动电话入网时，必须向乙方提供与每一张移动电话卡一一对应的实际使用人的有效证件，由乙方进行查验并登记实际使用人的身份信息。
        <br />5.甲方应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整，并有义务配合乙方进行查验。甲方可以通过乙方相应渠道查询已登记的身份信息。如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的，甲方应当及时到乙方营业网点更正或变更。因甲方登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由甲方自行承担。
        <br />6.甲方申请入网时，在中国电信全国范围内登记在同一个个人用户名下的移动电话卡达到5张的，乙方根据国家相关规定不再为其开办新的移动电话卡。
        <br />7.乙方要求甲方提供担保的，甲方应当按照乙方要求办理相应担保手续。
        <br />
        <p class="p1">二、甲方的权利和义务</p>1.甲方有权监督乙方的电信服务质量，及时提出改善意见和建议；对电信服务持有异议的，可以向乙方进行咨询或投诉。
        <br />2.甲方有权自主选择乙方依法提供的各类电信业务，有权自主选择乙方公示的资费方案，有权自主选择乙方提供的各类业务办理、咨询、查询和投诉渠道。
        <br />3.甲方有权自主选择取得入网许可的终端、设备，并负责安装、调测和维护（包括建筑内自建管线的维护）。甲方自主选择的终端、设备应当具备符合乙方网络的相应功能，否则可能无法支持所选择的电信服务，甲方将自行承担损失。
        <br />4.甲方对乙方提供给甲方使用但所有权归乙方的线路、设备或终端有保管责任，不得转借、出租、出售或赠与他人，也不得用于非乙方提供的业务中。由于甲方原因造成该等线路、设备或终端损坏、丢失的，甲方需承担修复费用或按价赔偿。
        <br />5.甲方应当按照约定的交费时间和方式及时、足额交纳电信费用。电信费用计费周期一般为自然月，即每月1日0时至当月最后一日24时，双方另有约定的除外。逾期交纳电信费用（欠费）的，甲方须每日按所欠费用3‰的标准支付违约金。由于通信设备产生费用清单及相关处理会有时延（如国际漫游业务等），可能会发生上一计费周期的部分电信费用计入下一计费周期收取的情况。
        <br />6.甲方可以自愿订购或退订第三方服务提供商通过乙方网络提供的增值电信服务，并同意乙方根据第三方服务提供商的委托代为收取相关费用。如甲方对代收费用有异议，可以向第三方或乙方提出，由第三方自行解决或由乙方协调第三方解决。
        <br />7.甲方对交纳的电信费用及代收费用有异议的，应当在乙方计费原始数据保存期限内向乙方提出。
        <br />8.甲方有权自主选择或终止以代扣、银行托收等方式支付电信费用，并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。
        <br />9.甲方通信自由和通信秘密受法律保护。公安机关、国家安全机关、人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，乙方将依法配合。
        <br />10.甲方不得二次转售、倒卖名下持有的电话卡。如需转让给他人使用或实际使用人信息发生变化，甲方须按本协议关于用户真实身份信息登记的约定，办理过户手续或变更实际使用人信息，否则乙方有权采取关停号码、解除协议等措施。由此产生的后果，乙方不承担责任。
        <br />11.甲方不得利用乙方通信资源、电信服务等制作、复制、发布、传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：
        <br />（1）反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的；
        <br />（2）危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        <br />（3）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；
        <br />（4）散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；
        <br />（5）散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；
        <br />（6）侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；
        <br />（7）发送违法信息、拨打诈骗电话，未经接收方同意或请求、或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；
        <br />（8）其他利用乙方通信资源、电信服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。
        <br />12.甲方不得实施或协助他人实施下列危害乙方网络安全和信息安全的行为，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：
        <br />（1）侵入或非法控制乙方网络或电信设备，盗接乙方电信线路、非法复制乙方电信码号；
        <br />（2）非法获取、删除、修改乙方网络或电信设备中存储、处理、传输的信息和应用程序；
        <br />（3）利用乙方网络从事窃取或破坏他人信息、损害他人合法权益的活动，制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；
        <br />（4）提供从事入侵乙方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；
        <br />（5）设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；
        <br />（6）故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；
        <br />（7）使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；
        <br />（8）危害乙方网络安全和信息安全的其他行为。
        <br />13.甲方同意乙方基于提供电信服务、与甲方沟通联系、改善服务质量等目的，收集、储存并使用甲方个人信息，包括身份信息、服务数据及日志信息等。身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、出生日期、身份证件号码、拍照人像、住址、电话号码、电子邮箱等；服务数据及日志信息包括但不限于甲方在使用乙方服务过程中上传和产生的位置信息、终端信息、通话记录、使用记录、订购信息、账单等。如甲方不同意提供或不同意乙方收集、储存并使用如上的某一或某些信息，甲方将可能无法成为乙方的用户或无法享受乙方提供的某些服务，或无法达到相关服务拟达到的效果。本协议解除或终止后，乙方不再收集新的甲方个人信息，并将按照相关法律法规规定对本协议存续期间收集的甲方个人信息进行处理。
        <br />14.为保护公共利益、优化电信服务质量、保障甲方电信服务知情权、及时警示通讯信息诈骗、创新电信服务体验，甲方同意乙方使用甲方个人信息通过营业网点、网站、短信、互联网媒体、电子邮件、语音外呼等方式，向甲方告知社会公益、电信服务、业务使用、诈骗风险警示等信息。
        <br />15.甲方有权自主选择携号转网服务，即在保持移动电话号码不变的情况下变更签约的电信运营企业。甲方办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。
        <br />16.办理携号转网业务过程中，甲方同意乙方将必要的甲方身份信息提供给工业和信息化部集中业务管理系统(CSMS系统)，用于比对携入方和携出方登记的身份信息是否一致。
        <br />
        <p class="p1">三、乙方的权利和义务</p>1.乙方在其通信网络与设施覆盖范围内，向甲方提供电信服务；其中，乙方在签订有自动漫游协议的国家和地区的电信运营商的网络覆盖范围内为甲方提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。乙方提供的电信服务应符合国家规定的电信服务质量标准。
        <br />2.乙方免费向甲方提供中华人民共和国境内（仅限大陆地区，不含港澳台）火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。
        <br />3.乙方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。
        <br />4.乙方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、通知或告知业务种类和服务项目、范围、时限、资费方案以及其他电信服务内容。
        <br />5.乙方可以通过电话热线、营业网点、网上营业厅、掌上营业厅或短信等多种渠道为甲方提供业务办理、咨询、查询、障碍申告、投诉受理等服务。
        <br />6.乙方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。
        <br />7.甲方向乙方申告除网络覆盖和终端设备故障外的电信服务障碍，乙方应当自接到申告之日起，城镇48小时、农村72小时内修复或调通。
        <br />8.乙方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常电信服务的，应当提前72小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。
        <br />9.乙方向甲方提供免费的电子账单服务，并有义务对账单进行解释。乙方对甲方计费原始数据保留期限为5个月（系统产生用户话单当月起后5个月，不含当月），法律另有规定除外。如数据保留期限内甲方对电信费用提出异议，乙方应当保存相应计费原始数据至异议解决。
        <br />10.乙方可以根据对甲方实际情况的综合评估，与甲方约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。
        <br />11.乙方有权根据具体业务类型按预付方式或后付方式向甲方收取电信费用：
        <br />（1）预付方式下，甲方需预存费用。当账户余额加上信用额度（如有）不足以支付甲方拟使用的电信业务费用时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务（“欠费停机”）。
        <br />（2）后付方式下，甲方累计未交的费用到达信用额度、或到达交费期时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务。其中，甲方当月累计出账费用的交费期为次月1日至月末，另有约定的除外。
        <br />（3）上述（1）、（2）欠费停机所涉业务，不包括单独的固定电话业务。固定电话业务的欠费停机时间按照《中华人民共和国电信条例》的相关规定执行。
        <br />12.甲方自欠费停机之日起60日内交清费用并申请复机的，乙方应当在收到甲方费用时起24小时内为甲方恢复暂停的电信服务（“复机”）。
        <br />13.如甲方自欠费停机之日起满60日仍未交清欠费和违约金，乙方有权自欠费停机第61日起终止提供电信服务，收回相应号码（“销户”）、解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金，并可以依法向征信机构提供甲方失信信息。
        <br />14.乙方承诺对甲方个人信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供，并应用行业通行的安全技术和管理手段（如脱敏展示、访问权限等）防止未经授权的访问和使用。
        <br />
        <p class="p1">四、特殊情况的责任承担</p>1.甲方入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由甲方自行承担责任。
        <br />2.甲方密码是甲方办理业务的重要凭证。甲方入网后应当立即设置甲方密码，并妥善保管。为方便办理业务，乙方也可以根据甲方申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。
        <br />3.凡使用甲方密码或随机密码定制、变更或取消业务的行为，均被视为甲方或甲方授权的行为。因甲方对密码保管不善造成的损失由甲方自行承担，但乙方有义务协助甲方或公安机关调查相关情况。
        <br />4.甲方应当合理管理电信费用支出，出现异常的高额电信费用时（指超过甲方此前3个月平均电信费用5倍以上的费用），乙方一经发现，应当尽可能迅速告知甲方，如告知后未得到甲方确认的，乙方有权暂停本协议约定的部分或全部服务。
        <br />5.在甲方欠费停机的情况下，乙方有权拒绝为甲方办理除交费、电信费用查询外的其他电信业务。
        <br />6.任何一方未履行或未完全履行本合同项下的义务，均构成违约。违约方应赔偿因违约给对方造成的直接损失。
        <br />7. 甲、乙双方约定的在网期限，以甲方所使用乙方提供的补贴、赠送等优惠资费期限及靓号承诺消费期限中的最长期限为准。约定在网期限内，甲方因自身原因终止使用电信服务行为的，均构成违约，须支付相应的违约金。违约金的计算方式以用户入网登记单、靓号协议及其他约定内容为准，合计支付。
        <br />8.因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任，但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。
        <br />9.甲方使用本协议项下电信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等），由第三方承担责任。对于甲方提出的异议，乙方可以协调第三方解决。
        <br />
        <p class="p1">五、协议的变更、终止和解除</p>1.本协议自甲方签字（甲方为自然人）或盖章（甲方为非自然人）且乙方盖章之日起生效；通过电子渠道、电子签名、电子协议或其他电子数据形式签订本协议的，甲方以点击确认等方式表示同意本协议的电子数据进入乙方系统的时间视为甲方签字时间。如甲方为办理号码携入并签订本协议的，甲、乙双方签字或盖章后，本协议于乙方收到携号转网成功生效结果告知之时起生效。
        <br />2.甲方如终止使用电信服务，应当结清电信费用及违约金（如有），并至乙方营业网点申请销户或号码携出，销户或号码携出后本协议终止。
        <br />3.未经双方同意并办理有关手续，甲、乙方不得将依据本协议办理的业务全部或部分转让给第三方。
        <br />4.甲方申请销户时仍有余额的，乙方通过甲方余额的原缴费方式退还甲方。甲方通过参与赠送、优惠、兑换等营销活动获取的余额，按营销活动当时的具体约定和业务规则处理。
        <br />5.因乙方电信业务经营许可证的业务范围、业务种类发生改变，或因技术进步、国家政策变动等原因，乙方无法向甲方继续提供原有业务或需对原有业务的服务方式、服务功能、操作方法、业务号码等进行调整时，乙方有权变更或终止本协议，但应当提前发布公告，提出解决方案，做好客户服务。
        <br />6.有下列情形之一的，乙方有权单方终止或解除协议：
        <br />（1）甲方未按照用户真实身份信息登记要求，提供的登记资料不真实、不准确、不完整、无效，或经乙方通知后未在合理期限内配合提供的；
        <br />（2）甲方存在本协议第二条第10款、第11款所列任一行为，或由此引起用户投诉或举报的；
        <br />（3）甲方以担保方式取得业务使用权的情况下，违反担保约定或担保人无能力履行担保义务的；
        <br />（4）甲方未办理相关手续，自行改变电信服务使用性质、用途，或二次转售、倒卖电话卡，或自行转让协议的；
        <br />（5）甲方利用乙方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案；
        <br />（6）甲方自欠费停机之日起60日内仍未交清所有欠费、违约金、赔偿金等费用的；
        <br />（7）有权机关要求乙方停止对甲方提供电信服务的；
        <br />（8）甲方原因造成本协议无法履行的；
        <br />（9）法律、法规、规章及规范性文件规定的其他情形。
        <br />7.本协议终止或解除后，乙方继续保留向甲方追交所欠费用的权利，并有权收回甲方原使用的业务号码（销户的号码）、IP地址等电信资源。为保证甲方权益，乙方对销户的业务号码至少冻结90日，方可重新分配给其他用户使用。
        <br />8.甲方改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用（如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、代扣等第三方协议，自行卸载甲方加载的第三方软件，避免造成个人信息泄露；否则由此造成甲方或其他人损失由甲方承担。
        <br />
        <p class="p1">六、其他约定</p>1.所有因本协议引起的或与本协议有关的争议，双方将通过友好协商解决。协商不成的，双方均同意采用以下第(2)种争议解决方式：
        <br />（1）请求位于本地的仲裁委员会依照其仲裁规则对争议事项进行仲裁。
        <br />（2）请求有管辖权的人民法院对争议的事项做出裁决。
        <br />2.如果本合同的任何条款在任何时候变成不合法、无效或不可强制执行但不从根本上影响本合同的效力时，本合同的其他条款不受影响。
        <br />3.甲方办理各类业务所签署的业务登记单、协议,乙方以公告等书面形式公开做出的服务承诺均为本协议的补充协议，与本协议冲突部分以补充协议为准。
        <br />4.本协议一式两份，双方各保留一份，具有同等法律效力。甲乙双方以电子方式签署本协议的，甲方可登录乙方网上营业厅查询、下载本协议。
        <br />
      </div>
    </van-action-sheet>
    <!-- 协议2 -->
    <van-action-sheet v-model:show="agreementShow1" title="关于信息收集使用规则公告">
      <div class="mid_top">
        <p class="p2">
          尊敬的客户:
          <br />《全国人民代表大会常务委员会关于加强网络信息保护的决定》《电信和互联网用户个人信息保护规定》(工业和信息化部令第24号)和《电话用户真实身份信息登记规定》(工业和信息化部令第25号)等国家法律法规的要求，客户在中国电信股份有限公司各类营业网点(含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等)办理固定电话、移动电话(含无线上网卡)入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
          <br />如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
          <br />为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息非因下列事由，不对外泄露客户个人信息
          <br />(a)事先获得客户的明确授权;
          <br />(b)根据有关的法律法规要求
          <br />(C)按照相关司法机关和/或政府主管部门的要求
          <br />(d)为维护社会公众的利益所必需且适当
          <br />(e)为维护我公司或客户的合法权益所必需且适当。
          <br />
        </p>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import areaList from '@/utils/area';
import { cardid } from '@/utils/validate';
import { ActionSheet } from 'vant';
import { cloneDeep } from 'lodash';
export default {
  setup() {
    const state = reactive({
      form: {
        walletNumber: '',
        consigneePhone: '',
        consigneeName: '',
        consigneeAddress: '',
        consigneeAddressName: '',
        consigneeAddressDetails: '',
        numberCardId: '',
        agentId: '',
      },
      checked: false,
      pattern: /^1\d{10}$/, //手机号码正则匹配
      agreementShow: false,
      agreementShow1: false,
      popupShow: false,
      cardInfo: {}
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $http: http, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      /**
       * @handle patternMobile validator validator3
       * @description 校验函数返回 true 表示校验通过，false 表示不通过
       */
      patternMobile(val) {
        return state.pattern.test(val); //手机号码验证
      },
      validator(val) {
        return /^[\u4e00-\u9fa5]+$/.test(val); //只能输入中文
      },
      patternCard(val) {
        const [result, message] = cardid(val);
        if (result) {
          return message;
        }
      },
      //确定area列表
      confirmArea(arr) {
        state.popupShow = false;
        state.form.consigneeAddress = arr[2].code
        state.form.consigneeAddressName = arr.map((item) => item.name).join(' ');
      },
      //关闭/打开area列表
      openOrCloseArea() {
        state.popupShow = !state.popupShow;
      },
      onSubmit(val) {
        const form = cloneDeep(state.form);
        if (!state.checked) {
          return tool.toast({
            msg: '请先阅读并同意协议以及公告',
            duration: 1500,
          });
        }
        http.post(`/api/submit`, form).then(({ data: res }) => {
            if (res.code == 0) {
              // tool.alert(
              //   {
              //     title: '提交成功',
              //     msg: '卡片申请提交成功',
              //   },
              //   () => {
              //     location.reload();
              //   }
              // );
              router.push({ name: 'receive-result' })
            } else {
              tool.alert({
                title: '提交错误',
                msg: res.msg,
              });
            }
        });
      },
    };
    onMounted(() => {
      const { agentId, goodsId } = route.query;
      Object.assign(state.form, { agentId, numberCardId:goodsId });
      const cardInfo = JSON.parse(sessionStorage.getItem('numCard'))
      state.cardInfo = cardInfo.zyNumberCardEntity
    });
    return {
      ...toRefs(state),
      ...methods,
      areaList,
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
.agreement {
  display: flex;
  justify-content: center;
  color: #a1a8b7;
  align-items: start;
  margin: 48px 0;
  .text {
    font-size: 24px;
    margin-left: 16px;
    width: 520px;
  }
  .blue {
    color: #2359de;
  }
}
.submit {
  background: linear-gradient(
    137.7deg,
    rgba(255, 153, 0, 1) 0%,
    rgba(255, 106, 58, 1) 100%
  );
  color: #fff;
  margin: 96px auto 24px;
  width: 568px;
}

.content {
  padding: 32px 24px;
}
.group {
  margin: 0;
  padding: 20px 18px 0;
  :deep(.van-field) {
    margin-bottom: 18px;
    background: #f8f8fa;
    border-radius: 16px;
  }
  :deep(.van-checkbox) {
    align-items: start;
  }
  .form-address {
    display: block;
  }
}
.mid_top {
  height: 700px;
  overflow-y: scroll;
  padding: 32px;
  box-sizing: border-box;
  color: #454d58;
  margin: 10px 0px;
  font-size: 24px;
  line-height: 40px;
  .title {
    line-height: 40px;
    position: relative;
  color: #454d58;
  }
  .p2 {
    font-size: 24px;
    line-height: 40px;
  color: #454d58;
  }
  .p1 {
    font-size: 24px;
  color: #454d58;
    line-height: 40px;
  }
}
</style>
